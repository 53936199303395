
export const GET_STUDENT = "GET_STUDENT"
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS"

export const GET_TEACHERSCHOOL = "GET_TEACHERSCHOOL"
export const GET_TEACHERSCHOOL_SUCCESS = "GET_TEACHERSCHOOL_SUCCESS"

export const GET_SCHOOL = "GET_SCHOOL"
export const GET_SCHOOL_SUCCESS = "GET_SCHOOL_SUCCESS"


export const ADD_SCHOOL = "ADD_SCHOOL"
export const ADD_SCHOOL_SUCCESS = "ADD_SCHOOL_SUCCESS"

export const UPDATE_SCHOOL = "UPDATE_SCHOOL"
export const UPDATE_SCHOOL_SUCCESS = "UPDATE_SCHOOL_SUCCESS"

export const DELETE_SCHOOL = "DELETE_SCHOOL"
export const DELETE_SCHOOL_SUCCESS = "DELETE_SCHOOL_SUCCESS"


export const GET_PURCH = "GET_PURCH"
export const GET_PURCH_SUCCESS = "GET_PURCH_SUCCESS"

export const ADD_PURCH = "ADD_PURCH"
export const ADD_PURCH_SUCCESS = "ADD_PURCH_SUCCESS"

export const UPDATE_PURCH = "UPDATE_PURCH"
export const UPDATE_PURCH_SUCCESS = "UPDATE_PURCH_SUCCESS"

export const DELETE_PURCH = "DELETE_PURCH"
export const DELETE_PURCH_SUCCESS = "DELETE_PURCH_SUCCESS"


export const DELETE_FEEDBACKCATEGORY = "DELETE_FEEDBACKCATEGORY"
export const DELETE_FEEDBACKCATEGORY_SUCCESS = "DELETE_FEEDBACKCATEGORY_SUCCESS"

export const ADD_FEEDBACKCATEGORY = "ADD_FEEDBACKCATEGORY"
export const ADD_FEEDBACKCATEGORY_SUCCESS = "ADD_FEEDBACKCATEGORY_SUCCESS"

export const UPDATE_FEEDBACKCATEGORY = "UPDATE_FEEDBACKCATEGORY"
export const UPDATE_FEEDBACKCATEGORY_SUCCESS = "UPDATE_FEEDBACKCATEGORY_SUCCESS"

export const GET_FEEDBACKCATEGORY = "GET_FEEDBACKCATEGORY"
export const GET_FEEDBACKCATEGORY_SUCCESS = "GET_FEEDBACKCATEGORY_SUCCESS"


export const GET_FEEDBACK = "GET_COACH"
export const GET_FEEDBACK_SUCCESS = "GET_COACHSUCCESS"


export const GET_COACH = "GET_COACH"
export const GET_COACHSUCCESS = "GET_COACHSUCCESS"

export const ADD_FEEDBACK = "ADD_FEEDBACK"
export const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS"

export const DELETE_FEEDBACK = "DELETE_FEEDBACK"
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS"

export const UPDATE_FEEDBACK = "UPDATE_FEEDBACK"
export const UPDATE_FEEDBACK_SUCCESS = "UPDATE_FEEDBACK_SUCCESS"


export const ADD_COACH = "ADD_COACH"
export const ADD_COACHSUCCESS = "ADD_COACHSUCCESS"

export const UPDATE_COACH = "UPDATE_COACH"
export const UPDATE_COACHSUCCESS = "UPDATE_COACHSUCCESS"

export const DELETE_COACH = "DELETE_COACH"
export const DELETE_COACHSUCCESS = "DELETE_COACHSUCCESS"


export const GET_SALES_POINT = "GET_SALES_POINT"
export const GET_SALES_POINTSUCCESS = "GET_SALES_POINTSUCCESS"

export const ADD_SALES_POINT = "ADD_SALES_POINT"
export const ADD_SALES_POINTSUCCESS = "ADD_SALES_POINTSUCCESS"

export const UPDATE_SALES_POINT = "UPDATE_SALES_POINT"
export const UPDATE_SALES_POINTSUCCESS = "UPDATE_SALES_POINTSUCCESS"


export const DELETE_SALES_POINT = "DELETE_SALES_POINT"
export const DELETE_SALES_POINTSUCCESS = "DELETE_SALES_POINTSUCCESS"


export const GET_COUNTER = "GET_COUNTER"
export const GET_COUNTER_SUCCESS = "GET_COUNTER_SUCCESS"

export const ADD_COUNTER = "ADD_COUNTER"
export const ADD_COUNTER_SUCCESS = "ADD_COUNTER_SUCCESS"

export const UPDATE_COUNTER = "UPDATE_COUNTER"
export const UPDATE_COUNTER_SUCCESS = "UPDATE_COUNTER_SUCCESS"

export const DELETE_COUNTER = "DELETE_COUNTER"
export const DELETE_COUNTER_SUCCESS = "DELETE_COUNTER_SUCCESS"


export const UPDATE_BOOK_SUCCESS = "UPDATE_BOOK_SUCCESS"
export const UPDATE_BOOK = "UPDATE_BOOK"


export const DELETE_BOOK_SUCCESS = "DELETE_BOOK_SUCCESS"
export const DELETE_BOOK = "DELETE_BOOK"

export const GET_BOOK = "GET_BOOK"
export const GET_BOOK_SUCCESS = "GET_BOOK_SUCCESS"

export const ADD_BOOK = "ADD_BOOK"
export const ADD_BOOK_SUCCESS = "ADD_BOOK_SUCCESS"


export const DELETE_POST = "DELETE_POST"
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS"

export const ADD_MOTIVATION = "ADD_MOTIVATION"
export const ADD_MOTIVATION_SUCCESS = "ADD_MOTIVATION_SUCCESS"

export const GET_MOTIVATION = "GET_MOTIVATION"
export const GET_MOTIVATION_SUCCESS = "GET_MOTIVATION_SUCCESS"

export const UPDATE_MOTIVATION = "UPDATE_MOTIVATION"
export const UPDATE_MOTIVATION_SUCCESS = "UPDATE_MOTIVATION_SUCCESS"

export const DELETE_MOTIVATION = "DELETE_MOTIVATION"
export const DELETE_MOTIVATION_SUCCESS = "DELETE_MOTIVATION_SUCCESS"

export const UPDATE_POST = "UPDATE_POST"
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS"

export const GET_POST = "GET_POST"
export const GET_POST_SUCCESS = "GET_POST_SUCCESS"

export const ADD_NEWPOST = "ADD_NEWPOST"
export const ADD_NEWPOST_SUCCESS = "ADD_NEWPOST_SUCCESS"

export const GET_SONG = "GET_SONG"
export const GET_SONG_SUCCESS = "GET_SONG_SUCCESS"

export const ADD_SONG = "ADD_SONG"
export const ADD_SONG_SUCCESS = "ADD_SONG_SUCCESS"

export const UPDATE_SONG = "UPDATE_SONG"
export const UPDATE_SONG_SUCCESS = "UPDATE_SONG_SUCCESS"

export const DELETE_SONG = "DELETE_SONG"
export const DELETE_SONG_SUCCESS = "DELETE_SONG_SUCCESS"

export const GET_PDR = "GET_PDR"
export const GET_PDR_SUCCESS = "GET_PDR_SUCCESS"

export const ADD_PDR = "ADD_PDR"
export const ADD_PDR_SUCCESS = "ADD_PDR_SUCCESS"

export const DELETE_PDR = "DELETE_PDR"
export const DELETE_PDR_SUCCESS = "DELETE_PDR_SUCCESS"

export const UPDATE_PDR = "UPDATE_PDR"
export const UPDATE_PDR_SUCCESS = "UPDATE_PDR_SUCCESS"


export const ADD_SONG_CATEGORY = "ADD_SONG_CATEGORY"
export const ADD_SONG_CATEGORY_SUCCESS = "ADD_SONG_CATEGORY_SUCCESS"
export const UPDATE_SONG_CATEGORY = "UPDATE_SONG_CATEGORY"
export const UPDATE_SONG_CATEGORY_SUCCESS = "UPDATE_SONG_CATEGORY_SUCCESS"
export const GET_SONG_CATEGORY_SUCCESS = "GET_SONG_CATEGORY_SUCCESS"
export const GET_SONG_CATEGORY= "GET_SONG_CATEGORY"
export const DELETE_SONG_CATEGORY = "DELETE_SONG_CATEGORY"
export const DELETE_SONG_CATEGORY_SUCCESS = "DELETE_SONG_CATEGORY_SUCCESS"

export const ADD_LIMIT = "ADD_LIMIT"
export const ADD_LIMIT_SUCCESS = "ADD_LIMIT_SUCCESS"

export const DELETE_LIMIT = "DELETE_LIMIT"
export const DELETE_LIMIT_SUCCESS = "DELETE_LIMIT_SUCCESS"

export const UPDATE_LIMIT = "UPDATE_LIMIT"
export const UPDATE_LIMIT_SUCCESS = "UPDATE_LIMIT_SUCCESS"

export const GET_LIMIT = "GET_LIMIT"
export const GET_LIMIT_SUCCESS = "GET_LIMIT_SUCCESS"

export const DELETE_SUBSUBCATEGORY = "DELETE_SUBSUBCATEGORY"
export const DELETE_SUBSUBCATEGORY_SUCCESS = "DELETE_SUBSUBCATEGORY_SUCCESS"

export const DELETE_PRODUCTSUBCATEGORY = "DELETE_PRODUCTSUBCATEGORY"
export const DELETE_PRODUCTSUBCATEGORY_SUCCESS = "DELETE_PRODUCTSUBCATEGORY_SUCCESS"

export const UPDATE_PRODUCTSUBCATEGORY = "UPDATE_PRODUCTSUBCATEGORY"
export const UPDATE_PRODUCTSUBCATEGORY_SUCCESS = "UPDATE_PRODUCTSUBCATEGORY_SUCCESS"

export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY"
export const ADD_SUBCATEGORY_SUCCESS = "ADD_SUBCATEGORY_SUCCESS"

export const ADD_BRAND = "ADD_BRAND"
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS"

export const UPDATE_SUBSUBCATEGORY = "UPDATE_SUBSUBCATEGORY"
export const UPDATE_SUBSUBCATEGORY_SUCCESS = "UPDATE_SUBSUBCATEGORY_SUCCESS"

export const ADD_SUBSUBCATEGORY = "ADD_SUBSUBCATEGORY"
export const ADD_SUBSUBCATEGORY_SUCCESS = "ADD_SUBSUBCATEGORY_SUCCESS"

export const GET_PRODUCTSUBSUBCATEGORY = "GET_PRODUCTSUBSUBCATEGORY"
export const GET_PRODUCTSUBSUBCATEGORY_SUCCESS = "GET_PRODUCTSUBSUBCATEGORY_SUCCESS"

export const UPDATE_BRAND = "UPDATE_BRAND"
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS"

export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS"
export const GET_BRAND = "GET_BRAND"

export const GET_PRODUCTSUBCATEGORY_SUCCESS = "GET_PRODUCTSUBCATEGORY_SUCCESS"
export const GET_PRODUCTSUBCATEGORY = "GET_PRODUCTSUBCATEGORY"

export const DELETE_SUBEDUCATION = "DELETE_SUBEDUCATION"
export const DELETE_SUBEDUCATION_SUCCESS = "DELETE_SUBEDUCATION_SUCCESS"

export const UPDATE_SUBEDUCATION = "UPDATE_SUBEDUCATION"
export const UPDATE_SUBEDUCATION_SUCCESS = "UPDATE_SUBEDUCATION_SUCCESS"

export const ADD_SUBEDUCATION = "ADD_SUBEDUCATION"
export const ADD_SUBEDUCATION_SUCCESS = "ADD_SUBEDUCATION_SUCCESS"


export const GET_ABOUT_SUCCESS = "GET_ABOUT_SUCCESS"

export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS"
export const UPDATE_EDUCATION = "UPDATE_EDUCATION"

export const ADD_EDUCATION = "ADD_EDUCATION"
export const ADD_EDUCATION_SUCCESS = "ADD_EDUCATION_SUCCESS"

export const GET_SUBEDUCATION = "GET_SUBEDUCATION"
export const GET_SUBEDUCATION_SUCCESS = "GET_SUBEDUCATION_SUCCESS"




export const GET_OFFER = "GET_OFFER"
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS"

export const DELETE_OFFER = "DELETE_OFFER"
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS"


export const DELETE_TEACHER = "DELETE_TEACHER"
export const DELETE_TEACHER_SUCCESS = "DELETE_TEACHER_SUCCESS"


export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS"
export const UPDATE_OFFER = "UPDATE_OFFER"

export const ADD_OFFER  = "ADD_OFFER"
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS"



export const GET_ADVERT = "GET_ADVERT"
export const GET_ADVERT_SUCCESS = "GET_ADVERT_SUCCESS"

export const DELETE_ADVERT = "DELETE_ADVERT"
export const DELETE_ADVERT_SUCCESS = "DELETE_ADVERT_SUCCESS"



export const UPDATE_ADVERT_SUCCESS = "UPDATE_ADVERT_SUCCESS"
export const UPDATE_ADVERT = "UPDATE_ADVERT"

export const ADD_ADVERT  = "ADD_ADVERT"
export const ADD_ADVERT_SUCCESS = "ADD_ADVERT_SUCCESS"





export const GET_AVAILABLE = "GET_AVAILABLE"
export const GET_AVAILABLE_SUCCESS = "GET_AVAILABLE_SUCCESS"

export const DELETE_AVAILABLE = "DELETE_AVAILABLE"
export const DELETE_AVAILABLE_SUCCESS = "DELETE_AVAILABLE_SUCCESS"



export const UPDATE_AVAILABLE_SUCCESS = "UPDATE_AVAILABLE_SUCCESS"
export const UPDATE_AVAILABLE = "UPDATE_AVAILABLE"

export const ADD_AVAILABLE  = "ADD_AVAILABLE"
export const ADD_AVAILABLE_SUCCESS = "ADD_AVAILABLE_SUCCESS"



export const GET_RESERVATION = "GET_RESERVATION"
export const GET_RESERVATION_SUCCESS = "GET_RESERVATION_SUCCESS"

export const DELETE_RESERVATION = "DELETE_RESERVATION"
export const DELETE_RESERVATION_SUCCESS = "DELETE_RESERVATION_SUCCESS"



export const UPDATE_RESERVATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS"
export const UPDATE_RESERVATION  = "UPDATE_EDUCATION"

export const ADD_RESERVATION  = "ADD_EDUCATION"
export const ADD_RESERVATION_SUCCESS = "ADD_EDUCATION_SUCCESS"


export const GET_PRODUCTCATEGORY = "GET_PRODUCTCATEGORY"
export const GET_PRODUCTCATEGORY_SUCCESS = "GET_PRODUCTCATEGORY_SUCCESS"

export const GET_PRODUCT = "GET_PRODUCT"
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"

export const GET_SLİDERS = "GET_SLİDERS"
export const GET_SLİDERS_SUCCESS = "GET_SLİDERS_SUCCESS"

export const GET_BLOGS = "GET_BLOGS"
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS"

export const GET_SSS = "GET_SSS"
export const GET_SSS_SUCCESS = "GET_SSS_SUCCESS"

export const DELETE_SSS = "DELETE_SSS"
export const DELETE_SSS_SUCCESS = "DELETE_SSS_SUCCESS"

export const GET_EDUCATION = "GET_EDUCATION"
export const GET_EDUCATION_SUCCESS = "GET_EDUCATION_SUCCESS"

export const DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS = "DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS"
export const DELETE_DYNAMICFORMEXTRAEXTRA = "DELETE_DYNAMICFORMEXTRAEXTRA"

export const DELETE_DYNAMICFORM = "DELETE_DYNAMICFORM"
export const DELETE_DYNAMICFORM_SUCCESS = "DELETE_DYNAMICFORM_SUCCESS"

export const DELETE_DYNAMICFORMEXTRA = "DELETE_DYNAMICFORMEXTRA"
export const DELETE_DYNAMICFORMEXTRA_SUCCESS = "DELETE_DYNAMICFORMEXTRA_SUCCESS"

export const DELETE_COURSESCHEDULES = "DELETE_COURSESCHEDULES"
export const DELETE_COURSESCHEDULES_SUCCESS = "DELETE_COURSESCHEDULES_SUCCESS"

export const ADD_GALLERYMULTIPLE = "ADD_GALLERYMULTIPLE"
export const ADD_GALLERYMULTIPLE_SUCCESS = "ADD_GALLERYMULTIPLE_SUCCESS"

export const DELETE_GALLERY = "DELETE_GALLERY"
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS"

export const ADD_GALLERY = "ADD_GALLERY"
export const ADD_GALLERY_SUCCESS = "ADD_GALLERY_SUCCESS"


export const ADD_PRODUCTCATEGORY = "ADD_PRODUCTCATEGORY"
export const ADD_PRODUCTCATEGORY_SUCCESS = "ADD_PRODUCTCATEGORY_SUCCESS"



export const ADD_SSS = "ADD_SSS"
export const ADD_SSS_SUCCESS = "ADD_SSS_SUCCESS"


export const ADD_BLOG= "ADD_BLOG"
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS"

export const DELETE_REFERANCES = "DELETE_REFERANCES"
export const DELETE_REFERANCES_SUCCESS = "DELETE_REFERANCES_SUCCESS"

export const DELETE_POPUP = "DELETE_POPUP"
export const DELETE_POPUP_SUCCESS = "DELETE_POPUP_SUCCESS"

export const DELETE_PRODUCTCATEGORY_SUCCESS = "DELETE_PRODUCTCATEGORY_SUCCESS"
export const DELETE_PRODUCTCATEGORY = "DELETE_PRODUCTCATEGORY"



export const ADD_PRODUCT= "ADD_PRODUCT"
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"

export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"

export const UPDATE_SSS= "UPDATE_SSS"
export const UPDATE_SSS_SUCCESS = "UPDATE_SSS_SUCCESS"

export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT = "DELETE_PRODUCT"

export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS"
export const DELETE_SLIDER = "DELETE_SLIDER"


export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS"
export const DELETE_BLOG = "DELETE_BLOG"

export const DELETE_NEWS = "DELETE_NEWS"
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS"
export const DELETE_MEGA_SUCCESS = "DELETE_MEGA_SUCCESS"
export const DELETE_MEGA = "DELETE_MEGA"
export const ADD_MEGA_SUCCESS = "ADD_MEGA_SUCCESS"

export const GET_DYNAMICFORM_EXTRA_EXTRA = "GET_DYNAMICFORM_EXTRA_EXTRA"
export const GET_DYNAMICFORMEXTRAEXTRA_SUCCESS = "GET_DYNAMICFORMEXTRAEXTRA_SUCCESS"
export const GET_DYNAMICFORMEXTRA_SUCCESS = "GET_DYNAMICFORMEXTRA_SUCCESS"
export const GET_DYNAMICFORM_EXTRA = "GET_DYNAMICFORM_EXTRA"
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS"
export const GET_CLASSES = "GET_CLASSES"
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"


export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS"
export const GET_GALLERY = "GET_GALLERY"

export const UPDATE_WORKSHOP2_SUCCESS = "UPDATE_WORKSHOP2_SUCCESS"

export const UPDATE_SUBDOMAIN = "UPDATE_SUBDOMAIN"

export const UPDATE_NEWS = "UPDATE_NEWS"

export const UPDATE_PROJECT = "UPDATE_PROJECT"

export const UPDATE_DYNAMICFORM = "UPDATE_DYNAMICFORM"
export const GET_DYNAMICFORM_SUCCESS = "GET_DYNAMICFORM_SUCCESS"
export const UPDATE_DYNAMICFORM_SUCCESS = "UPDATE_DYNAMICFORM_SUCCESS"


export const ADD_DYNAMICFORM_SUCCESS = "ADD_DYNAMICFORM_SUCCESS"

export const UPDATE_SLIDER = "UPDATE_SLIDER"
export const UPDATE_SLIDER_SUCCESS = "UPDATE_SLIDER_SUCCESS"

export const UPDATE_SUCCESS_PROJECT = "UPDATE_SUCCESS_PROJECT"
export const UPDATE_SUCCESS_GALLERY = "UPDATE_SUCCESS_GALLERY"

export const UPDATE_COURSESCHEDULES = "UPDATE_COURSESCHEDULES"
export const UPDATE_SUBDOMAIN_SUCCESS = "UPDATE_SUBDOMAIN_SUCCESS"
export const UPDATE_WORKSHOPSESSION_SUCCESS = "UPDATE_WORKSHOPSESSION_SUCCESS"
export const UPDATE_SUCCESS_COURSESCHEDULES = "UPDATE_SUCCESS_COURSESCHEDULES"
export const UPDATE_SUCCESS_TEACHER = "UPDATE_SUCCESS_TEACHER"

export const UPDATE_SUCCESS_NEWS = "UPDATE_SUCCESS_NEWS"
export const UPDATE_SUCCESS_MEGA = "UPDATE_SUCCESS_MEGA"
export const UPDATE_MEGA = "UPDATE_MEGA"
export const UPDATE_WORKSHOP2 = "UPDATE_WORKSHOP2"
export const UPDATE_GALLERY = "UPDATE_GALLERY"
export const UPDATE_REFERANCES = "UPDATE_REFERANCES"
export const UPDATE_REFERANCES_SUCCESS = "UPDATE_REFERANCES_SUCCESS"

export const UPDATE_BLOG = "UPDATE_BLOG"
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS"
export const UPDATE_PRODUCTCATEGORY = "UPDATE_PRODUCTCATEGORY"
export const UPDATE_PRODUCTCATEGORY_SUCCESS = "UPDATE_PRODUCTCATEGORY_SUCCESS"
export const GET_TEACHER_WORKSHOP = "GET_TEACHER_WORKSHOP"
export const GET_WORKSHOP2_SUCCESS = "GET_WORKSHOP2_SUCCESS"
export const GET_SUBPROJECT_SUCCESS = "GET_SUBPROJECT_SUCCESS"

export const GET_AUTHORITY = "GET_AUTHORITY"
export const GET_CONTACT = "GET_CONTACT"
export const GET_ABOUT = "GET_ABOUT"
export const UPDATE_CONTACT = "UPDATE_CONTACT"
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS"
export const UPDATE_ABOUT = "UPDATE_ABOUT"
export const UPDATE_ABOUT_SUCCESS = "UPDATE_ABOUT_SUCCESS"

export const GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS"
export const UPDATE_AUTH = "UPDATE_AUTH"

export const ADD_SLIDER_SUCCESS = "ADD_SLIDER_SUCCESS"

export const GET_WORKSHOP2 = "GET_WORKSHOP2"
export const GET_REFERANCES = "GET_REFERANCES"
export const GET_REFERANCES_SUCCESS = "GET_REFERANCES_SUCCESS"
export const ADD_REFERANCES = "ADD_REFERANCES"
export const ADD_REFERANCES_SUCCESS = "ADD_REFERANCES_SUCCESS"
export const ADD_DYNAMIC_FORM = "ADD_DYNAMIC_FORM"
export const ADD_SLIDER = "ADD_SLIDER"
export const ADD_SLIDER_SUCCES = "ADD_SLIDER_SUCCES"

export const GET_AUTHUPDATE_SUCCESS = "GET_AUTHUPDATE_SUCCESS"
export const GET_SUBPROJECT = "GET_SUBPROJECT"
export const GET_CLASSATTENDANCE = "GET_CLASSATTENDANCE"
export const GET_CLASSATTENDANCE_SUCCESS = "GET_CLASSATTENDANCE_SUCCESS"

export const UPDATE_LESSONS_TIME = "UPDATE_LESSONS_TIME"
export const ADD_LESSONSTIME_SUCCESS = "ADD_LESSONSTIME_SUCCESS"
export const ADD_LESSONS_TIME = "ADD_LESSONS_TIME"
export const ADD_WORKSHOP2 = "ADD_WORKSHOP2"
export const ADD_APPSETTING = "ADD_APPSETTING"
export const ADD_WORKSHOPSUBDOMAIN = "ADD_WORKSHOPSUBDOMAIN"
export const ADD_COURSE = "ADD_COURSE"
export const ADD_MEGA = "ADD_MEGA"
export const ADD_NEWS = "ADD_NEWS"
export const ADD_PROJECT = "ADD_PROJECT"

export const ADD_POPUP = "ADD_POPUP"
export const UPDATE_TEACHER = "UPDATE_TEACHER"
export const UPDATE_WORKSHOPSESSION = "UPDATE_WORKSHOPSESSION"
export const UPDATE_POPUP = "UPDATE_POPUP"
export const UPDATE_POPUP_SUCCESS = "UPDATE_POPUP_SUCCESS"
export const GET_POPUP_SUCCESS = "GET_POPUP_SUCCESS"
export const GET_TEACHERWORKSHOP_SUCCESS = "GET_TEACHERWORKSHOP_SUCCESS"
export const UPDATE_LESSONSTIME_SUCCESS = "UPDATE_LESSONSTIME_SUCCESS"

export const GET_WORKSHOP_SUCCESS = "GET_WORKSHOP_SUCCESS"
export const GET_WORKSHOPSESSION_SUCCESS = "GET_WORKSHOPSESSION_SUCCESS"
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS"
export const GET_SLIDER_SUCCESS = "GET_SLIDER_SUCCESS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"
export const GET_MEGA_SUCCESS = "GET_MEGA_SUCCESS"
export const GET_TEACHER_SUCCESS = "GET_TEACHER_SUCCESS"
export const GET_DYNAMIC_SUCCESS = "GET_DYNAMIC_SUCCESS"

/* POPUP */
export const GET_POPUP = "GET_POPUP"
export const GET_WORKSHOP = "GET_WORKSHOP"
export const GET_CourseSchedulesMega = "GET_CourseSchedulesMega"
export const GET_WorkshopSessionMega = "GET_WorkshopSessionMega"
export const GET_TeacherMega = "GET_TeacherMega"
export const GET_EVENT = "GET_EVENT"
export const GET_DYNAMICFORM = "GET_DYNAMICFORM"
export const GET_SLIDER = "GET_SLIDER"
export const GET_PROJECTSNEW = "GET_PROJECTSNEW"
export const GET_NEWS = "GET_NEWS"
export const GET_MEGA = "GET_MEGA"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"


/**
 * add user
 */
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_POPUP_SUCCESS= "ADD_POPUP_SUCCESS"

export const ADD_USER_FAIL = "ADD_USER_FAIL"
/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
