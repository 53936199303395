import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";


// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";


import {

  getUsers as onGetUsers,
  getStudent as onGetUsersStudent,

} from "store/contacts/actions";
const Dashboard = props => {

  const dispatch = useDispatch();
  const [getUserType, setUserType] = useState(null);


  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));

  const { studentUser } = useSelector(state => ({
    studentUser: state.contacts.studentUser,
  }));


  const reports = [
    {
      title:  getUserType == 4 ? "Toplam Koçlar" : getUserType == 3? "Toplam Öğrenciler" : "Toplam Üyeler",
      iconClass: "bx-purchase-tag-alt",
      description: getUserType == 4 ? studentUser.length :  getUserType == 3? studentUser.length:  users.length,
    },

  ];



  useEffect(() => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    setUserType(authUser.Users_Type_ID)
    if (studentUser && !studentUser.length) {
      if(authUser != null){
        if(authUser.Teacher.length >0){
          dispatch(onGetUsersStudent(authUser.Teacher[0].ID));
        }
      }
     
    }
  }, [dispatch, studentUser]);

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
    }
  }, [dispatch, users]);




  //meta title
  document.title = "Anasayfa | EfsoKoç Admin Paneli";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Yönetim")}
            breadcrumbItem={props.t("Anasayfa")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>

                 
                    
                         

                  </Col>
                ))}
              </Row>

          
           
             
            </Col>

           



          </Row>

         
         
        </Container>
      </div>

   
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
