import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Button
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname,Date, Status } from "./contactlistCol";
import DateModal from "components/Common/DateModal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";

import Dropzone from 'react-dropzone-uploader';
import {ExportToExcel2} from '../../../ExportToExcel2'

import {
  getReservation as onGetReservation,
  addReservation as onAddReservation,
  updateReservation as onUpdateProduct,
  deleteReservation as onDeleteReservation,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import classnames from "classnames";
import FilterModal from "components/Common/FilterModal";
import { useNavigate } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import toastr from "toastr";
import "toastr/build/toastr.min.css";


const ContactsList = props => {
  const tableRef = useRef(null);
  const navigate = useNavigate();

  //meta title
  document.title = "Dersler | EfsoKoç Admin PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getExcelModal, setExcelModal] = useState(false)
  const [step, setstep] = useState(0)
  const [getIsActive, setIsActive] = useState(false);
  const [getIsLessonType, setIsLessonType] = useState(null);


  const [getLesson, setLesson] = useState([{
    ID : 1,
    Users_Lessons_Type_Title: "Ortaokul"
  },{
    ID : 2 ,
    Users_Lessons_Type_Title: "Lise"

  },
]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Lesson_Title) || "",
      sort: (contactEdit && contactEdit.Sort) || "",

    },
    validationSchema: Yup.object({
    }),
    onSubmit: values => {

      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Lesson_Title:values.title,
          Sort:values.sort,
         // IsActive:getIsActive,
          Users_Lesson_Type_ID:getLessonType,
        };
        dispatch(onUpdateProduct(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {

        console.log("şlmsdfl",getLessonType)
        const newUser = {
            Lesson_Title:values["title"],
          Sort: parseInt(values["sort"]),
          // IsActive:getIsActive,
          Users_Lesson_Type_ID: parseInt(getLessonType),
        };

        console.log("newUser123",newUser)
      
        dispatch(onAddReservation(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });


  
  const { users } = useSelector(state => ({
    users: state.contacts.reservation,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalModal, setModalModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);
  const [getCategoryFilter, setCategoryFilter] = useState([]);

  const [getUser, setUser] = useState([]);

  const [getType, setType] = useState(false);

  const [getUserType, setUserType] = useState(null);


  
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
    
      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


    
      
      {
        Header: "Başlık",
        accessor: "Lesson_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "Öğrenim Durumu",
        accessor: "Users_Lessons_Type.Users_Lessons_Type_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

    

      {
        Header: "Sıralama",
        accessor: "Sort",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">


              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );



  const [getFilterCheck, setFilterCheck] = useState(false);
  const [getFilterModal, setFilterModal] = useState(false);
  const [getFilterCheckModal, setFilterCheckModal] = useState(false);
  
  const [getFilterData, setFilterData] = useState([]);
  const [totalCashPrice, setTotalCashPrice] = useState(0);

  

  
  const _getAuthData2 = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersDemir/select/`)
      .then((res) => {
        setUser(res.data)
      })
  }



  useEffect(() => {
 
    if (users && !users.length) {

      let authUser = JSON.parse(localStorage.getItem("authUser"));
   
        dispatch(onGetReservation(null))
      

     
      setIsEdit(false);
    }
  }, [dispatch, users ]);



  useEffect(() => {
    
    _getAuthData2()
    
   
  }, [dispatch, getUser ]);
  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleModal = () => {
    setModalModal(!modalModal);
  };


  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

   
    setIsEdit(true);
    //setSelectedOption(event.target.id);
    

    setIsActive(user.IsActive)
    setLessonType(user.Users_Lesson_Type_ID)


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteReservation(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"


    
    const data2 =  users.filter(x=> x.Last_Login_Date == null).map(elt=> [
      elt.NameSurname,
      elt.Last_Login_Date,
        ]);
    
    const ws = XLSX.utils.json_to_sheet(data2);
    
    ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
    ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };


    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }

    const exportToCSVLoginLastMonth= () => {


      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
      setExcelModal(false)
  
      console.log("lksdklf")
      let fileName = getSelectDate + getSelectDateFinish
  
      const data2 = users.filter(x => x.Created_DateTime >= getSelectDate && x.Created_DateTime <= getSelectDateFinish).map(elt => [
      elt.Orders_TotalPrice,
      elt.Payment_Select.Payment_Select_Text,
      moment(elt.Created_DateTime).format('DD/MM/YYYY'),
        ]);
  
        const ws = XLSX.utils.json_to_sheet(data2);
      
        ws["A1"] = { v: "Tutar", t: "s" };
        ws["B1"] = { v: "Ödeme Tipi", t: "s" };
        ws["C1"] = { v: "Tarih", t: "s" };

        
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension)
  
    }
    const exportToCSVDontLoginLastMonth= () => {


      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
      setExcelModal(false)
  
      let fileName = "Son1AyGirisYapmayanlar"
  
  
      const data2 = users.filter(x => x.Last_Login_Date < getSelectDate || x.Last_Login_Date > getSelectDateFinish).map(elt => [
      elt.NameSurname,
      moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
        ]);
  
        const ws = XLSX.utils.json_to_sheet(data2);
      
        ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
        ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
      
      
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension)
  
    }
    const [getUserID, setUserID] = useState(null);
    const [getPaymentID, setPaymentID] = useState(null);
    const [getUserLimit, setUserLimit] = useState(null);
    const [getUserLimitID, setUserLimitID] = useState(null);

    
    const onClickExcelLogin = (order) => {
      setExcelModal(true);
      setType('login')
    }

    const exportToCSVLogin = () => {
  
      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
      setExcelModal(false)
  
      let fileName = "GirisYapmayanlar"
  
    const data2 =  users.filter(x=> x.Last_Login_Date != null).map(elt=> [
      elt.NameSurname,
      moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
        ]);
  
        const ws = XLSX.utils.json_to_sheet(data2);
      
        ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
        ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
      
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension)
  
    }

    const total = getFilterCheckModal == true ? getFilterData.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0) : users.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0);

    
  const _setChoiceUser = arg => {
    
    const [id, limitPrice] = arg.target.value.split('|');

    setUserID(`${id}|${limitPrice}`)

    setUserLimit(`${limitPrice}`)

    if(totalCashPrice > limitPrice){
      showToast('Sıha Otomasyon', "Sipariş Kullanıcının Limitini Geçiyor!", false)

    }else{
      showToast('Sıha Otomasyon', "Sipariş Tutarı Kullanıcının Limiti Geçmiyor", true)

    }

  }


  function showToast(type,message,type2) {

    let ele = null
  
     if(type2 == true){
       ele = "success"
       message = message
    } else{
       ele = "error"
       message = message 
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }


  const _onFilterClick = (val,active) => {
  

    if(active == true){
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)
    }else{
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)

    }
 
  }

  const [selectedOption, setSelectedOption] = useState(null);

  
  const _handleChange = (event) => {
    try {

    setSelectedOption(event.target.id);

    const data2 =  getCategory.filter(x=> x.Product_Brand_ID == event.target.id)

    setCategoryFilter(data2)

  } catch (err) {
    console.log(err)
    }
    
  };
  const [getLessonType, setLessonType] = useState(null);



const _setChoiceLessonType = arg => {
  setLessonType(arg.target.value)
}

  return (
    <React.Fragment>
    

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={getType == "login" ? exportToCSVLoginLastMonth: exportToCSVDontLoginLastMonth}
        show={getExcelModal}
        type={getType}
        onCloseClick={() => setExcelModal(false)}
      />
      

      <FilterModal
        handleSelectChangeCompany= {_onFilterClick}
        show={getFilterModal}
        getCategoryCompany={getUser}
        onCloseClick={() => setFilterModal(false)}
     
      />

         
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Dersler" />



          {  getUserType == 1 && getFilterCheckModal == false ? 
<Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterModal(true)}
              >
                <i className="mdi mdi-plus me-1" />
                Firmaya Göre Filtrele
              </Button>:
              getUserType == 1 ?
              <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterCheckModal(false)}
              >
                <i className="mdi mdi-plus me-1" />
                Listeyi Sıfırla
              </Button> : null}

{/**  <ExportToExcel2 title={"Tarihe Göre Excel"} onDownloadClick={exportToCSVLoginLastMonth} onClickExcel={onClickExcelLogin} apiData={users} fileName={"fileName"} /> */}
        
     

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <TableContainer
                    columns={columns}
                    data={getFilterCheckModal == true ? getFilterData : getFilterCheck == true ? getFilterData : users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddOrder={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

        


<Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kategori Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Başlığı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Eğitim Durumu Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getLessonType}
                                onChange={(text) => _setChoiceLessonType(text)}
                                value={
                                  getLessonType
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>


                                {getLesson.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Users_Lessons_Type_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Sıralama</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="number"
                                placeholder="Sıralama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>

                          



                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
