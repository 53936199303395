import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Email, GSM,Date,Status } from "./contactlistCol";

import DateModal from "components/Common/DateModal";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";
import FilterModal from "components/Common/FilterModal";

import {
  getSubEducation as onGetSubEducation,
  addSubEducation as onAddSubEducation,
  updateSubEducation as onUpdateSubEducation,
  deleteSubEducation as onDeleteSubEducation,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ExportToExcel2 } from "ExportToExcel2";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Alt Başlık | EfsoKoç ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getUser, setUser] = useState([]);
  const [getFilterCheckModal, setFilterCheckModal] = useState(false);
  const [getFilterModal, setFilterModal] = useState(false);
  const [getFilterData, setFilterData] = useState([]);
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);

  const [getType, setType] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Book_SubSubject_Title) || "",
      desc: (contactEdit && contactEdit.LessonSubSubject_Desc) || "",
      sort: (contactEdit && contactEdit.Sort) || "",

    },
    validationSchema: Yup.object({
    
    }),
    onSubmit:async (values) => {

      if (isEdit) {
        const updateUser = {
          ID: contactEdit.ID,
          Book_SubSubject_Title: values.title,
          Sort: values.sort,
          IsActive: getIsActive,
          Book_Subject_ID: getCategoryID,
        };
        dispatch(onUpdateSubEducation(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {


        const newUser = {
          Book_SubSubject_Title: values["title"],
          Sort: values["sort"],
          IsActive: getIsActive,
          Book_Subject_ID: getCategoryID,
        };
        // save new user

   
       dispatch(onAddSubEducation(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.educationSubItem,
  }));
  const [modal, setModal] = useState(false);
    const [getExcelModal, setExcelModal] = useState(false)

  const [isEdit, setIsEdit] = useState(false);
  const [getIsActive, setIsActive] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Başlık",
        accessor: "Book_SubSubject_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Konu",
        accessor: "Book_Subject.Book_Subject_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Sıralama",
        accessor: "Sort",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Aktiflik Durumu",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },


    

     {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">


<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      } 
    ],
    []
  );


  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonSubjectEfso/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()

  }, [getCategory]);


  useEffect(() => {
    if (users && !users.length) {


        dispatch(onGetSubEducation(null))
            
    
    
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
   // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };



  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setCategoryID(user.Book_Subject_ID)

    setIsActive(user.IsActive)
    setIsEdit(true);


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteSubEducation(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };




  const exportToCSVLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    console.log("lksdklf")
    let fileName = getSelectDate + getSelectDateFinish

    const data2 = users.filter(x => x.Payment_CreatedDateTime >= getSelectDate && x.Payment_CreatedDateTime <= getSelectDateFinish).map(elt => [
    elt.PaymentTotalAmount,
    elt.Payment_Type,
    elt.Users.Users_NameSurname,
    moment(elt.Payment_CreatedDateTime).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Tutar", t: "s" };
      ws["B1"] = { v: "Ödeme Tipi", t: "s" };
      ws["B1"] = { v: "Kullanıcı", t: "s" };
      ws["C1"] = { v: "Tarih", t: "s" };

      
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }
  const exportToCSVDontLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "Son1AyGirisYapmayanlar"


    const data2 = users.filter(x => x.Last_Login_Date < getSelectDate || x.Last_Login_Date > getSelectDateFinish).map(elt => [
    elt.NameSurname,
    moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
      ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }


  const _onFilterClick = (val,active) => {
  

    if(active == true){
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)
    }else{
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)

    }
 
  }

  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"


    
    const data2 =  users.filter(x=> x.Last_Login_Date == null).map(elt=> [
      elt.NameSurname,
      elt.Last_Login_Date,
        ]);
    
    const ws = XLSX.utils.json_to_sheet(data2);
    
    ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
    ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };


    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }


  const total = getFilterCheckModal == true ? getFilterData.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0) : users.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0);

  return (
    <React.Fragment>

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={getType == "login" ? exportToCSVLoginLastMonth: exportToCSVDontLoginLastMonth}
        show={getExcelModal}
        type={getType}
        onCloseClick={() => setExcelModal(false)}
      />
      

<FilterModal
        handleSelectChangeCompany= {_onFilterClick}
        show={getFilterModal}
        getCategoryCompany={getUser}
        onCloseClick={() => setFilterModal(false)}
     
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Alt Başlık Gir" />





          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
      
              
                  <TableContainer
                    columns={columns}
                    data={getFilterCheckModal == true ? getFilterData : users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddPayment={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />


                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "AltBaşlık Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                       
                        
                      
                            <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                                          

                            <div className="mb-3">
                              <Label className="form-label">Sıralama</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="number"
                                placeholder="Sıralama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>
                            


                            <div className="d-flex">
                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                         
                        </div>


                        <div className="mb-3">
                              <Label className="form-label">Kitap Başlığı Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>


                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Book_Subject_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>


                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
