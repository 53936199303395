import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Email, GSM,Date,Status } from "./contactlistCol";

import DateModal from "components/Common/DateModal";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";
import FilterModal from "components/Common/FilterModal";

import {
  getSchool as onGetSchool,
  addSchool as onAddSchool,
  updateSchool as onUpdateSchool,
  deleteSchool as onDeleteSchool,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ExportToExcel2 } from "ExportToExcel2";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Okullar | EfsoKoç ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getUser, setUser] = useState([]);
  const [getFilterCheckModal, setFilterCheckModal] = useState(false);
  const [getFilterModal, setFilterModal] = useState(false);
  const [getFilterData, setFilterData] = useState([]);
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const [getSelectedValuesUsers, setSelectedValuesUsers] = useState([]);

  

  const [getType, setType] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.School_Title) || "",
      desc: (contactEdit && contactEdit.School_Desc) || "",
      city: (contactEdit && contactEdit.School_City) || "",
      desc: (contactEdit && contactEdit.School_Desc) || "",

    },
    validationSchema: Yup.object({
    
    }),
    onSubmit:async (values) => {

      if (isEdit) {
        const updateUser = {
          ID: contactEdit.ID,
          School_Title: values.title,
          School_Desc: values.desc,
          School_City: values.city,
          IsActive: getIsActive,
        };
        dispatch(onUpdateSchool(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {


        const newUser = {
          School_Title: values["title"],
          School_Desc: values["desc"],
          School_City: values["city"],
          IsActive: getIsActive,
        };
        // save new user

   
       dispatch(onAddSchool(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.school,
  }));
  const [modal, setModal] = useState(false);
    const [getExcelModal, setExcelModal] = useState(false)
    const [modalLocation, setModalLocation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getIsActive, setIsActive] = useState(false);
  const [getUsersDefault, setUsersDefault] = useState([]);
  const [getSchoolID, setSchoolID] = useState([]);

  

  const toggleLocation = () => {
    setModalLocation(!modalLocation);
  };

  
  const _deleteUser = (ID) => {

    axios.delete(`https://sihaadmin.stechomeyazilim.info:8182/deleteSchoolOfUsersEfso/${ID}`).then(async(res) => {

      console.log("lmsdşcclg",res)
      setTimeout(async() => {
      
        dispatch(onGetSchool());
       
      }, 1000)
  
    })
  }
  
  
  function showToast(type) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Kullanıcı Tanımlandı"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const handleUserClickLocation = (arg) => {

    setUsersDefault(arg.SchoolOfUsers);

    setSchoolID(arg.ID);

    toggleLocation()
  }
  
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Başlık",
        accessor: "School_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Açıklama",
        accessor: "School_Desc",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "İl",
        accessor: "School_City",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Aktiflik Durumu",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },

      {
        Header: "Öğretmenler",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.TeacherOfScholl == null ? null : cellProps.TeacherOfScholl.length > 0 ? (
              cellProps.TeacherOfScholl.map((item, index) => (
              <div key={index}>
                {item.Users != null ? <span >
                  {item.Users.ID} - {item.Users.Users_NameSurname}
                </span> : null}
              </div>))
            ) : (
              <div>
               <span >
                 Öğretmen Yok
                </span>
              </div>
            )}
          </>
        ),
      },

      {
        Header: "Kullanıcıları",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.SchoolOfUsers == null ? null : cellProps.SchoolOfUsers.length > 0 ? (
              cellProps.SchoolOfUsers.map((item, index) => (
              <div key={index}>
                {item.Users != null ? 
                <span >
                  <Button onClick={()=> _deleteUser(item.ID)}>
                    Sil
                  </Button>
                  {item.Users.ID} - {item.Users.Users_NameSurname}
                </span> : null}
              </div>))
            ) : (
              <div>
               <span >
                 Kullanıcı Yok
                </span>
              </div>
            )}
          </>
        ),
      },

    

     {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">


<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickLocation(userData);
                }}
              >
                <i className="mdi mdi-plus font-size-18" id="addtooltip" />
                <UncontrolledTooltip placement="top" target="addtooltip">
                  Yetkili Ekle
                </UncontrolledTooltip>
              </Link>



<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      } 
    ],
    []
  );


  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonSubjectEfso/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    if (getCategory && !getCategory.length) {

    _getAuthData()

    }
  }, [getCategory]);


  useEffect(() => {
    if (getUser && !getUser.length) {

    _getUsersData()
    }

  }, [getUser]);

  const _getUsersData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersEfso/select/`)
      .then((res) => {
        setUser(res.data)
      })
  }


  useEffect(() => {
    if (users && !users.length) {


        dispatch(onGetSchool(null))
            
    
    
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
   // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };



  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

   

    setIsActive(user.IsActive)
    setIsEdit(true);


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteSchool(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };




  const exportToCSVLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    console.log("lksdklf")
    let fileName = getSelectDate + getSelectDateFinish

    const data2 = users.filter(x => x.Payment_CreatedDateTime >= getSelectDate && x.Payment_CreatedDateTime <= getSelectDateFinish).map(elt => [
    elt.PaymentTotalAmount,
    elt.Payment_Type,
    elt.Users.Users_NameSurname,
    moment(elt.Payment_CreatedDateTime).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Tutar", t: "s" };
      ws["B1"] = { v: "Ödeme Tipi", t: "s" };
      ws["B1"] = { v: "Kullanıcı", t: "s" };
      ws["C1"] = { v: "Tarih", t: "s" };

      
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }
  const exportToCSVDontLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "Son1AyGirisYapmayanlar"


    const data2 = users.filter(x => x.Last_Login_Date < getSelectDate || x.Last_Login_Date > getSelectDateFinish).map(elt => [
    elt.NameSurname,
    moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
      ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }


  const _onFilterClick = (val,active) => {
  

    if(active == true){
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)
    }else{
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)

    }
 
  }

  const handleSelectChangeCompany = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValuesUsers(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }


  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"



    const data2 =  users.filter(x=> x.Last_Login_Date == null).map(elt=> [
      elt.NameSurname,
      elt.Last_Login_Date,
        ]);
    
    const ws = XLSX.utils.json_to_sheet(data2);
    
    ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
    ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };


    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }

  const [searchTerm, setSearchTerm] = useState('');

      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }


    const handleSearchChange = (e) => {
      const term = e.target.value;
      setSearchTerm(term);
  
      // Kullanıcının girdiği metne göre seçenekleri filtrele
      const filtered = getUser.filter(option =>
          option.Users_NameSurname.toLowerCase().includes(term.toLowerCase())
      );
  
      setFilteredOptions(filtered);
  };


  const _addUsers = () => {
    try {  
      console.log("şlsdmfşl",getSelectedValuesUsers,getSchoolID)
      getSelectedValuesUsers.map(item => {

      console.log("item121",item)

      const location = {
        School_ID:getSchoolID,
        Users_ID:parseFloat(item),
      }

      axios.post('https://sihaadmin.stechomeyazilim.info:8182/addSchoolOfUsersEfso/send', location).then(response => {

        console.log("lmsdşlg",response)

        showToast(true)
      setTimeout(async() => {
      
        dispatch(onGetSchool());
       
      }, 1000)

      
      })
}
    )

    toggleLocation()

  } catch (error) {
    console.log("_addLocation MapScreen")
    console.log(error)
  }
  }
  
  return (
    <React.Fragment>

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={getType == "login" ? exportToCSVLoginLastMonth: exportToCSVDontLoginLastMonth}
        show={getExcelModal}
        type={getType}
        onCloseClick={() => setExcelModal(false)}
      />
      

<FilterModal
        handleSelectChangeCompany= {_onFilterClick}
        show={getFilterModal}
        getCategoryCompany={getUser}
        onCloseClick={() => setFilterModal(false)}
     
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Okul Detay Gir" />


          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
      
              
              
                  <TableContainer
                    columns={columns}
                    data={getFilterCheckModal == true ? getFilterData : users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddSchool={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />


<Modal  size="lg" isOpen={modalLocation} toggle={toggleLocation}>
                    <ModalHeader toggle={toggleLocation} tag="h4">
                      {!!isEdit ? "Düzenle" : "Konum Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();  // Sayfa yenilemesini engeller
                          _addUsers()
                        }}
                      >

	
     
      {/* Yeni enlem ve boylam alanı eklemek için bir düğme */}

      <div className="mb-3">
                              <Label className="form-label">Kullanıcı Seçiniz</Label>

                              <div className="mb-3">
                              <input
                type="text"
                className="form-control"
                placeholder="Arama yapın..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            </div>
            
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getSelectedValuesUsers}
                                multiple
                                onChange={(text) => handleSelectChangeCompany(text)}
                                value={
                                  getSelectedValuesUsers
                                }>
                                {filteredOptions.length > 0 ? filteredOptions.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          )) :  getUser.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                         
                   
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Okul Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                       
                        
                      
                            <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                                          

                  <div className="mb-3">
                              <Label className="form-label">Açıklama Text</Label>
                              <Input
                                name="desc"
                                label="desc"
                                type="text"
                                placeholder="Açıklama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.desc || ""}
                                invalid={
                                  validation.touched.desc &&
                                    validation.errors.desc
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.desc &&
                                validation.errors.desc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.desc}
                                </FormFeedback>
                              ) : null}
                            </div>
                          

                            <div className="mb-3">
                              <Label className="form-label">İl</Label>
                              <Input
                                name="city"
                                label="city"
                                type="text"
                                placeholder="İl Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.city || ""}
                                invalid={
                                  validation.touched.city &&
                                    validation.errors.city
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.city &&
                                validation.errors.city ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                            


                            <div className="d-flex">
                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                         
                        </div>



                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
