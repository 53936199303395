import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  getLimit as onGetLimit,
  addLimit as onAddLimit,
  updateLimit as onUpdateLimit,
  deleteLimit as onDeleteLimit,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import { TL } from "../Products/contactlistCol";
import moment from "moment";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Base64 } from 'js-base64';


const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Koçlar | EfsoKoç ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getTeacherID, setTeacherID] = useState();

  const [getFileSubMedia, setFileSubMedia] = useState([]);
  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);

  const [getSubMediaID, setSubMediaID] = useState();
  const [getUser, setUser] = useState([]);
  const [getIsActive, setIsActive] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [getUsers, setUsers] = useState([]);
  const [getHtmlDataLong, setHtmlDataLong] = useState();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      shortDesc: (contactEdit && contactEdit.Teacher_ShortDesc) || null,
      historyDesc: (contactEdit && contactEdit.Teacher_History) || null,
      teacherPrice: (contactEdit && contactEdit.Teacher_Price) || null,
      teacherPhone: (contactEdit && contactEdit.Customer_TelephoneNumber) || null,
      teacherExperience: (contactEdit && contactEdit.Teacher_Experience) || null,
    },
    validationSchema: Yup.object({


    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Teacher_Url: getFileName,
          Teacher_ShortDesc: values.shortDesc,
         // Teacher_Desc: values.desc,
          Teacher_History: values.historyDesc,
          Teacher_Price: values.teacherPrice,
          IsActive: getIsActive,
          Customer_TelephoneNumber: values.teacherPhone,
          Teacher_Experience: values.teacherExperience,
          Teacher_Desc:Base64.encode(getHtmlDataLong),
        };
        dispatch(onUpdateLimit(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Teacher_Url: getFileName,
          Teacher_ShortDesc: values["shortDesc"],
         // Teacher_Desc: values.desc,
          Teacher_History: values["historyDesc"],
          Teacher_Price: values["teacherPrice"],
          IsActive: getIsActive,
          Customer_TelephoneNumber: values["teacherPhone"],
          Teacher_Experience: values["teacherExperience"],
          Teacher_Desc:Base64.encode(getHtmlDataLong),
        };
        // save new user
        dispatch(onAddLimit(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.limit,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [modalUsers, setModalUser] = useState(false);

  

  const [isEdit, setIsEdit] = useState(false);
  const [getFileName, setFileName] = useState(null);

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "ID",
        accessor: "Users.ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.Users != null ? !cellProps.Users.ProfilePhoto ? (
              <div className="avatar-xs">
                {cellProps.Users.ProfilePhoto != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Users_NameSurname}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.Users.ProfilePhoto}
                  alt=""
                />
              </div>
            ) : null}
          </>
        ),
      },

    
      
      {
        Header: "Kullanıcı Adı",
        accessor: "Users.Users_NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "Telefon Numarası",
        accessor: "Users.Users_TelephoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Kısa Açıklama",
        accessor: "Teacher_ShortDesc",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      
   
      {
        Header: "Öğrencileri",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.Teacher_OfUsers == null ? null : cellProps.Teacher_OfUsers.length > 0 ? (
              cellProps.Teacher_OfUsers.map((item, index) => (
              <div key={index}>
                {item.Users != null ? <span >
                  {item.Users.ID} - {item.Users.Users_NameSurname}
                </span> : null}
              </div>))
            ) : (
              <div>
               <span >
                 Öğrencisi Yok
                </span>
              </div>
            )}
          </>
        ),
      },

      

      {
        Header: "Aktif Mi?",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },



      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
  <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickUser(userData);
                }}
              >
                <i className="mdi mdi-account font-size-18" id="edittoolteacher" />
                <UncontrolledTooltip placement="top" target="edittoolteacher">
                  Öğrenci Ekle
                </UncontrolledTooltip>
              </Link>
            

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );


  onSubmit: values => {
    var localDate = moment().local();

    selectedUsers.map((item, index) => {

        const newUser = {
          title: values.title,
          body: values.desc,
          USERID:item,
          icerikTuru:"kullanici"
        }
  
        axios.post('https://sihaadmin.stechomeyazilim.info:8182/pushnotificationKupon1/send', newUser).then(response => {
      
      })

      const newUser2 = {
        IsActive:true,
        Created_DateTime:localDate,
        Notification_Desc:values.desc,
        Notification_Title:values.title,
        Users_ID: item,
        Notification_Type_ID:2,
        Notification_Seen:false
      }

      axios.post('https://sihaadmin.stechomeyazilim.info:8182/addNotificationKupon/send', newUser2).then(response => {
      })


    }) 

    selectedValuesGroup.map((item, index) => {

      let filterData = getGroupUser.filter((x)=> x.GroupType_ID == item)


      filterData.map((item, index) => {

        const newUser = {
          title: values.title,
          body: values.desc,
          USERID:item.Users_ID,
          icerikTuru:"kullanici"
        }
  
        axios.post('https://kuponadmin.stechomeyazilim.info:9595/pushnotificationKupon1/send', newUser).then(response => {
      
      })

      const newUser2 = {
        IsActive:true,
        Created_DateTime:localDate,
        Notification_Desc:values.desc,
        Notification_Title:values.title,
        Users_ID: item.Users_ID,
        Notification_Type_ID:2,
        Notification_Seen:false
      }

      axios.post('https://kuponadmin.stechomeyazilim.info:9595/addNotificationKupon/send', newUser2).then(response => {
      })

      })
     

    }) 
      

    selectedValuesVip.map((item, index) => {

      let filterData = getUsers.filter((x)=> x.ID == item)


      filterData.map((item, index) => {

        const newUser = {
          title: values.title,
          body: values.desc,
          USERID:item.ID,
          icerikTuru:"kullanici"
        }
  
        axios.post('https://kuponadmin.stechomeyazilim.info:9595/pushnotificationKupon1/send', newUser).then(response => {
      })

      const newUser2 = {
        IsActive:true,
        Created_DateTime:localDate,
        Notification_Desc:values.desc,
        Notification_Title:values.title,
        Users_ID: item.ID,
        Notification_Type_ID:2,
        Notification_Seen:false
      }

      axios.post('https://kuponadmin.stechomeyazilim.info:9595/addNotificationKupon/send', newUser2).then(response => {
      })

      })
     

    }) 
      
    selectedValuesJob.map((item, index) => {

      let filterData = getUsers.filter((x)=> x.Job_ID == item)

      filterData.map((item, index) => {

        const newUser = {
          title: values.title,
          body: values.desc,
          USERID:item.ID,
          icerikTuru:"kullanici"
        }
  
        axios.post('https://kuponadmin.stechomeyazilim.info:9595/pushnotificationKupon1/send', newUser).then(response => {
      })

      const newUser2 = {
        IsActive:true,
        Created_DateTime:localDate,
        Notification_Desc:values.desc,
        Notification_Title:values.title,
        Users_ID: item.ID,
        Notification_Type_ID:2,
        Notification_Seen:false
      }

      axios.post('https://kuponadmin.stechomeyazilim.info:9595/addNotificationKupon/send', newUser2).then(response => {
      })
      
      })
     

    }) 


    selectedValuesGender.map((item, index) => {

      let filterData = getUsers.filter((x)=> x.GenderID == item)


      filterData.map((item, index) => {

        const newUser = {
          title: values.title,
          body: values.desc,
          USERID:item.ID,
          icerikTuru:"kullanici"
        }
  
        axios.post('https://kuponadmin.stechomeyazilim.info:9595/pushnotificationKupon1/send', newUser).then(response => {
      })

      const newUser2 = {
        IsActive:true,
        Created_DateTime:localDate,
        Notification_Desc:values.desc,
        Notification_Title:values.title,
        Users_ID: item.ID,
        Notification_Type_ID:2,
        Notification_Seen:false
      }

      axios.post('https://kuponadmin.stechomeyazilim.info:9595/addNotificationKupon/send', newUser2).then(response => {
      })

       
      })
     

    }) 

}


const _addUsers = async () => {

  var localDate = moment().local();


  selectedUsers.map((item, index) => {


     const newUser2 = {
              Users_ID:item,
              Teacher_ID:getTeacherID,
              Created_DateTime:localDate,
  
    }

    console.log("şlmsdşlf",item,getTeacherID)

    axios.post('https://sihaadmin.stechomeyazilim.info:8182/addTeacherUsersEfso/send', newUser2).then(response => {
      console.log("lşmsdfş",response)
      toggleUsers()

      setTimeout(() => dispatch(onGetLimit()), 1000)

      
    })

    })
   

    showToast(true)
    validation.resetForm();
 
}


function showToast(type) {
  let ele = null
  let message = "";

   if(type == true){
     ele = "success"
     message = "Başlık Bildirim Başarıyla Tanımlandı"
  } else{
     ele = "error"
     message = getFalseDesc
  };
  const position = ["toast-top-right"];
  let toastType;
  const title = "Başlık";


  //Close Button
  const closeButton = true;

  //Debug
  const debug = false;

  //Progressbar
  const progressBar = true;

  //Duplicates
  const preventDuplicates = false;

  //Newest on Top
  const newestOnTop = true;

  //position class
  let positionClass = "toast-top-right";

  //Show Easing
  const showEasing = "swing";

  //Hide Easing
  const hideEasing = "linear";

  //show method
  const showMethod = "fadeIn";

  //Hide method
  const hideMethod = "fadeOut";

  //show duration
  const showDuration = 300;

  //Hide duration
  const hideDuration = 1000;

  //timeout
  const timeOut = 5000;

  //extended timeout
  const extendedTimeOut = 1000;

  //Fetch checked Type
 
   toastType = ele;
  

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: closeButton,
    debug: debug,
    progressBar: progressBar,
    preventDuplicates: preventDuplicates,
    newestOnTop: newestOnTop,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration
  };

  // setTimeout(() => toastr.success(`Settings updated `), 300)
  //Toaster Types
  if (toastType === "info") toastr.info(message, title);
  else if (toastType === "warning") toastr.warning(message, title);
  else if (toastType === "error") toastr.error(message, title);
  else toastr.success(message, title);
}
  const _getAuthData2 = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersEfso/select/`)
      .then((res) => {
        setUser(res.data)
      })
  }
  const [getUserID, setUserID] = useState(null);
    
  useEffect(() => {
    _getAuthData2()
   
  }, [getUser]);

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetLimit());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleUsers = () => {
    setModalUser(!modalUsers);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };


  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }


  const handleUserClickUser = (data) => {
    toggleUsers();

    setTeacherID(data.ID)
    console.log("şlmsdşfl",data.ID)
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);


    setIsEdit(true);

    
    setIsActive(user.IsActive)

    setFileName(user.Teacher_Url)
    setUserID(user.Users_ID)

    if(user.Teacher_Desc != null){
      setHtmlDataLong(Base64.decode(user.Teacher_Desc))
    }

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteLimit(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };



  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const _deleteMedia = async (itemData) => {

    try {
          
    axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteProductImageLuxun/${itemData.ID}`).then(async(res) => {
      
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsMediaLuxun/select/${getSubMediaID}`)
    .then((res) => {
      setFileSubMedia(res.data)
    })

    
    })
    } catch (error) {
      //yield put(updateUserFail(error))
    }


  /*  const filteredData = getFileSubMediaAdd.filter(item => item.ID !== itemData.ID )
    setFileSubMediaAdd(filteredData);*/

  }

  const _addMedia = async() => {


    getFileSubMediaAdd.map(async(item, index) => {
      const newUser = {
        Products_ID: getSubMediaID,
        //EducationSubItem_Media_Type: item.fileType,
        Url: item.fileName
      }

      axios.post('https://getjob.stechomeyazilim.info:5101/addProductsMediaLuxun/send', newUser)

      await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsMediaLuxun/select/${getSubMediaID}`)
      .then((res) => {
        setFileSubMedia(res.data)
      })

      dispatch(onGetProduct());

      toggleMedia();
    })
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }

  const _setChoiceUser = arg => {
    setUserID(arg.target.value)
  }


  const handleSelectChangeUsers = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedUsers(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Koçlar" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddLimit={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modalUsers} toggle={toggleUsers}>
                    <ModalHeader toggle={toggleUsers} tag="h4">
                      {!!isEdit ? "Kullanıcı Düzenle" : "Kullanıcı Ekle"}
                    </ModalHeader>
                    <ModalBody>

                      <Row>
                        <Col xs={12}>

                        <div className="mb-3">
                        <Label className="form-label">Koç Seçiniz</Label>
                        <Input
                          name="group"
                          type="select"
                          className="form-select"
                          multiple
                          value={selectedUsers} // Burada selectedValues, bileşenin seçili değerlerini içeren bir durumdur
                          onChange={(event) => handleSelectChangeUsers(event)}
                        >

                          {getUser.filter((x)=> x.Users_Type_ID != 3).map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          ))}

                        </Input>
                       
                      </div>

                     
                        </Col>
                      </Row>
                      <Row>

                     


                        <Col>
                          <div className="text-end">
                            <button
                              onClick={() => _addUsers()}
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Kullanıcıları Kaydet
                            </button>
                          </div>
                        </Col>
                      </Row>

                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Ürün Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>

                            <div className="mb-3">
                              <Label className="form-label">Kısa Açıklama</Label>
                              <Input
                                name="shortDesc"
                                label="shortDesc"
                                type="text"
                                placeholder="Kısa Açıklama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.shortDesc || ""}
                                invalid={
                                  validation.touched.shortDesc &&
                                    validation.errors.shortDesc
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.nameSurname &&
                                validation.errors.nameSurname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.nameSurname}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">historyDesc</Label>
                              <Input
                                name="historyDesc"
                                label="historyDesc"
                                type="text"
                                placeholder="historyDesc Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.historyDesc || ""}
                                invalid={
                                  validation.touched.historyDesc &&
                                    validation.errors.historyDesc
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.historyDesc &&
                                validation.errors.historyDesc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.historyDesc}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama</Label>

                              <Form method="post">
                                <CKEditor
                                  editor={Editor}
                                  data={getHtmlDataLong}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin]
                                  }}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHtmlDataLong(data)

                                  }}
                                />
                              </Form>
                            </div>
                            
                            <div className="mb-3">
                              <Label className="form-label">Fiyat</Label>
                              <Input
                                name="teacherPrice"
                                label="teacherPrice"
                                type="number"
                                placeholder="Fiyat Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.teacherPrice || ""}
                                invalid={
                                  validation.touched.teacherPrice &&
                                    validation.errors.teacherPrice
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.teacherPrice &&
                                validation.errors.teacherPrice ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.teacherPrice}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Telefon Numarası</Label>
                              <Input
                                name="teacherPhone"
                                label="teacherPhone"
                                type="tel"
                                placeholder="Fiyat Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.teacherPhone || ""}
                                invalid={
                                  validation.touched.teacherPhone &&
                                    validation.errors.teacherPhone
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.teacherPhone &&
                                validation.errors.teacherPhone ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.teacherPhone}
                                </FormFeedback>
                              ) : null}
                            </div>
                            
                            <div className="mb-3">
                            <Label className="form-label">Kullanıcı Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          defaultValue={getUserID}
                          onChange={(text) => _setChoiceUser(text)}
                          value={getUserID} // Change this line
                        >
                            <option value="">Lütfen Seçim Yapınız</option>

                          {getUser.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          ))}

                        </Input>
                            
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Deneyim</Label>
                              <Input
                                name="teacherExperience"
                                label="teacherExperience"
                                type="number"
                                placeholder="Deneyim Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.teacherExperience || ""}
                                invalid={
                                  validation.touched.teacherExperience &&
                                    validation.errors.teacherExperience
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.teacherExperience &&
                                validation.errors.teacherExperience ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.teacherExperience}
                                </FormFeedback>
                              ) : null}
                            </div>

                         

                           { getFileName != null ?   <div>
                <img
                  className=" avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + getFileName}
                  alt=""
                />
              </div> : null}



              <div className="d-flex">
                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                         
                        </div>
                        


                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
