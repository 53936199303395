import React from "react";
import { Navigate } from "react-router-dom";


import UserProfile from "../pages/Authentication/user-profile";



import Users from "../pages/Contacts/Users/contacts-list";
import UsersSchool from "../pages/Contacts/Users/contacts-listSchool";
import UsersTeacher from "../pages/Contacts/Users/contacts-listTeacher";

import Product from "../pages/Contacts/Products/contacts-list";

import BrandCategory from "../pages/Contacts/BrandCategory/contacts-list";

import ProductsCategory from "../pages/Contacts/ProductsCategory/contacts-list";
import ProductsSubCategory from "../pages/Contacts/ProductsSubCategory/contacts-list";
import ProductsSubSubCategory from "../pages/Contacts/ProductsSubSubCategory/contacts-list";

import Blogs from "../pages/Contacts/Blog/contacts-list";
import Pdr from "../pages/Contacts/Pdr/contacts-list";

import SSS from "../pages/Contacts/SSS/contacts-list";
import Sliders from "../pages/Contacts/Slider/contacts-list";
import Payment from "../pages/Contacts/Payment/index";

import Available from "../pages/Contacts/Available/contacts-list";
import Advert from "../pages/Contacts/Advert/contacts-list";
import Offer from "../pages/Contacts/Offer/contacts-list";
import Reservation from "../pages/Contacts/Reservation/contacts-list";
import Contacts from "../pages/Contacts/Contact/addContact";
import About from "../pages/Contacts/About/addContact";
import EducationList from "../pages/Contacts/EducationList/contacts-list";
import EducationSubList from "../pages/Contacts/EducationSubList/contacts-list";
import School from "../pages/Contacts/School/contacts-list";



import Book from "../pages/Contacts/Book/contacts-list";

import UsersList from "../pages/Contacts/ContactList/contacts-list";
import AuthorityList from "../pages/Contacts/AuthorityList/authority-list";

import PopupList from "../pages/Popup/PopupList/contacts-list";

import TeacherList from "../pages/Contacts/TeacherList/contacts-list";
import DynamicList from "../pages/Contacts/DynamicList/contacts-list";
import Song from "../pages/Contacts/Song/contacts-list";

import Bildirim from "../pages/Contacts/Bildirim/contacts-list";
import Sms from "../pages/Contacts/Sms/contacts-list";
import SalesPoints from "../pages/Contacts/SalesPoints/contacts-list";
import PostCoach from "../pages/Contacts/PostCoach/contacts-list";

import Post from "../pages/Contacts/Post/contacts-list";
import Motivation from "../pages/Contacts/Motivation/contacts-list";
import Counter from "../pages/Contacts/Counter/contacts-list";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/AuthenticationInner/Register2";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";

// Dashboard
import Dashboard from "../pages/Dashboard/index";


import DashboardTeacher from "../pages/Dashboard/index";


import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index";

import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

import SongCategory from "../pages/Contacts/SongCategory/contacts-list";

import Purch from "../pages/Contacts/Purch/contacts-list";


const authProtectedRoutes = [
  { path: "/dashboardTeacher", component: <DashboardTeacher /> },

  { path: "/dashboard", component: <Dashboard /> },
  //{ path: "/feedback", component: <FeedBack /> },
 // { path: "/feedbackcategory", component: <FeedBackCategory /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/products", component: <Product /> },
  { path: "/productscategory", component: <ProductsCategory /> },
  { path: "/productsSubcategory", component: <ProductsSubCategory /> },
  { path: "/productsSubSubcategory", component: <ProductsSubSubCategory /> },

  { path: "/brandCategory", component: <BrandCategory /> },
  { path: "/payment", component: <Payment /> },

  { path: "/sliders", component: <Sliders /> },
  { path: "/pdr", component: <Pdr /> },

  { path: "/blogs", component: <Blogs /> },
  { path: "/contact", component: <Contacts /> },
  { path: "/users", component: <Users /> },
  { path: "/usersSchool", component: <UsersSchool /> },
  { path: "/usersTeacher", component: <UsersTeacher /> },

  { path: "/available", component: <Available /> },
  { path: "/advert", component: <Advert /> },
  //Ecommerce
 
  { path: "/authorityList", component: <AuthorityList /> },

  { path: "/payment2", component: <EducationSubList /> },

  { path: "/book", component: <Book /> },

  { path: "/educationList", component: <EducationList /> },
  { path: "/usersList", component: <UsersList /> },
  { path: "/taksit", component: <PopupList /> },
  
  { path: "/school", component: <School /> },

  { path: "/bildirim2", component: <TeacherList /> },

  { path: "/dinamikForm", component: <DynamicList /> },
  { path: "/salesPoint", component: <SalesPoints /> },
  { path: "/postCoach", component: <PostCoach /> },

  { path: "/offer", component: <Offer /> },
  { path: "/orders", component: <Reservation /> },
  { path: "/about", component: <About /> },
  
  { path: "/SSS", component: <SSS /> },
  
  { path: "/songcategory", component: <SongCategory /> },

  { path: "/purch", component: <Purch /> },

  { path: "/bildirim", component: <Bildirim /> },
  { path: "/sms", component: <Sms /> },
  { path: "/song", component: <Song /> },
  { path: "/post", component: <Post /> },
  { path: "/motivation", component: <Motivation /> },

  { path: "/counter", component: <Counter /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  { path: "/crypto-ico-landing", component: <CryptoIcoLanding /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  { path: "/auth-two-step-verification-2", component: <TwostepVerification2 /> },
];

export { authProtectedRoutes, publicRoutes };
