import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";
import toastr from "toastr";
import "toastr/build/toastr.min.css";


import {
  getStudent as onGetUsersStudent,
  addUser as onAddUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Öğrencilerim | EfsoKoç Öğretmen PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getLessonTypeID, setLessonTypeID] = useState(null);
  const [getLessonType, setLessonType] = useState([{
    ID : 1,
    Users_Lessons_Type_Title: "Ortaokul"
  },{
    ID : 2 ,
    Users_Lessons_Type_Title: "Lise"

  },
]);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: (contactEdit && contactEdit.Users_NameSurname) || "",
      tel: (contactEdit && contactEdit.Users_TelephoneNumber) || "",
      password: (contactEdit && contactEdit.Users_LoginPasswordCode) || "",
      email: (contactEdit && contactEdit.Users_Email) || "",
      

    },
    validationSchema: Yup.object({
 
      username: Yup.string(3).required("Kullanıcı adı Giriniz"),

    }),
    onSubmit: values => {
      if (isEdit) {
        var localDate = moment().local();

        const updateUser = {
          ID: contactEdit.ID,
          Users_NameSurname: values.username,
          Users_TelephoneNumber: values.tel,
          Users_LoginPasswordCode: values.password,
        //  UsersPhoto:getFileName,
          Users_Type_ID: getCategoryID,
          Users_Email:values.email,
          Users_Lessons_Type_ID:getLessonTypeID,
          Users_CreatedDateTime:localDate

        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        var localDate = moment().local();

        const newUser = {
          Users_NameSurname: values["username"],
          Users_LoginUsernameCode: values["username"],
          MailAdress:values["email"],
          Users_TelephoneNumber:values["tel"],
          Users_LoginPasswordCode: values["password"],
        //  UsersPhoto:getFileName,
          Users_Type_ID: 2,
          Users_Lessons_Type_ID:getLessonTypeID,
          Users_CreatedDateTime:localDate
        };
        
        axios.post('https://sihaadmin.stechomeyazilim.info:8182/addUsersEfso/send', newUser).then(response => {
          
          let authUser = JSON.parse(localStorage.getItem("authUser"));

          const newTeacher = {
            //Created_DateTime:'caglarr.PNG',
            Teacher_ID:authUser.Teacher[0].ID,
            Users_ID:response.data.value[0].ID
          };

          
          axios.post('https://sihaadmin.stechomeyazilim.info:8182/addTeacherUsersEfso/send', newTeacher).then(responseTeacher=> {
          
            showToast(true)

        })

      })


      let authUser = JSON.parse(localStorage.getItem("authUser"));

      setTimeout(() => {
        if(authUser != null){
          if(authUser.Teacher.length >0){
            dispatch(onGetUsersStudent(authUser.Teacher[0].ID));
            setIsEdit(false);
          }
        }
      }, 1000)

       
       
    
        validation.resetForm();
      }
      toggle();
    },
  });

  function showToast(type) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Öğrenci Tanımlandı"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const { studentUser } = useSelector(state => ({
    studentUser: state.contacts.studentUser,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [getCategoryID, setCategoryID] = useState(null);


  const [getCategory, setCategory] = useState([]);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Adı",
        accessor: "Users.Users_NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Öğrenim Durumu",
        accessor: "Users.Users_Lessons_Type.Users_Lessons_Type_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      
      {
        Header: "Kayıt Tarihi",
        accessor: "Users.Users_CreatedDateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      
      {
        Header: "Telefon Numarası",
        accessor: "Users.Users_TelephoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },




      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

         

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
             

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleDeleteUser(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
             
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersTypeEfso/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()

    let authUser = JSON.parse(localStorage.getItem("authUser"));

    if (studentUser && !studentUser.length) {
      if(authUser != null){
        if(authUser.Teacher.length >0){
          dispatch(onGetUsersStudent(authUser.Teacher[0].ID));
          setIsEdit(false);
        }
      }
     
    }
  }, [dispatch, studentUser]);

  const toggle = () => {
    setModal(!modal);
  };


  const handleUserClick = (arg) => {

    console.log("ksdmfkş",arg)
    setEditContact(arg);

    setCategoryID(arg.User.Users_Type_ID)
    setLessonTypeID(arg.User.Users_Lessons_Type_ID)


    setIsEdit(true);


    toggle();
  };


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);



  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const _setChoiceLessonType= arg => {
    setLessonTypeID(arg.target.value)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Öğrenci Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <TableContainer
                    columns={columns}
                    data={studentUser}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddStudy={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Öğrenci Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>



                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Adı</Label>
                              <Input
                                name="username"
                                label="username"
                                type="text"
                                placeholder="Kullanıcı Adını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username &&
                                    validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                                validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Öğrenim Durumunu Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getLessonTypeID}
                                onChange={(text) => _setChoiceLessonType(text)}
                                value={
                                  getLessonTypeID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>


                                {getLessonType.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Users_Lessons_Type_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Email Adresi</Label>
                              <Input
                                name="email"
                                label="email"
                                type="text"
                                placeholder="Email Adresi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                          
               

                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Şifre</Label>
                              <Input
                                name="password"
                                label="password"
                                type="text"
                                placeholder="Kullanıcı Şifresi"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Telefon</Label>
                              <Input
                                name="tel"
                                label="tel"
                                type="text"
                                placeholder="Kullanıcı Telefon"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tel || ""}
                                invalid={
                                  validation.touched.tel &&
                                    validation.errors.tel
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.tel &&
                                validation.errors.tel ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tel}
                                </FormFeedback>
                              ) : null}
                            </div>
                           

                       
                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
