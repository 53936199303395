import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname } from "./contactlistCol";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";



import {
  getTeacherSchool as onGetTeacherSchool,
  addUser as onAddUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kullanıcı | EfsoKoç Admin PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getLessonType, setLessonType] = useState([{
    ID : 1,
    Users_Lessons_Type_Title: "Ortaokul"
  },{
    ID : 2 ,
    Users_Lessons_Type_Title: "Lise"

  },
]);

  

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: (contactEdit && contactEdit.Teacher.Users.Users_NameSurname) || "",
      tel: (contactEdit && contactEdit.Teacher.Users.Users_TelephoneNumber) || "",
      password: (contactEdit && contactEdit.Teacher.Users.Users_LoginPasswordCode) || "",
      email: (contactEdit && contactEdit.Teacher.Users.Users_Email) || "",
    },
    validationSchema: Yup.object({
 
      username: Yup.string(3).required("Kullanıcı adı Giriniz"),

    }),
    onSubmit: values => {
      if (isEdit) {
        var localDate = moment().local();

        const updateUser = {
          ID: contactEdit.ID,
          Users_NameSurname: values.username,
          Users_TelephoneNumber: values.tel,
          Users_LoginPasswordCode: values.password,
        //  UsersPhoto:getFileName,
          Users_Type_ID: getCategoryID,
          Users_Email:values.email,
          Users_CreatedDateTime:localDate
        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        var localDate = moment().local();

        const newUser = {
          Users_NameSurname: values["username"],
          Users_LoginUsernameCode: values["username"],
          MailAdress:values["email"],
          Users_TelephoneNumber:values["tel"],
          Users_LoginPasswordCode: values["password"],
        //  UsersPhoto:getFileName,
          Users_Type_ID: 3,
          Users_CreatedDateTime:localDate
        };
        
        axios.post('https://sihaadmin.stechomeyazilim.info:8182/addUsersEfso/send', newUser).then(response => {
          
          const newTeacher = {
            Teacher_NameSurname: values["username"],
            Teacher_Url:'caglarr.PNG',
            IsActive:true,
            Users_ID:response.data.value[0].ID
          };
          console.log("ömccsdlf",newTeacher)

          
          axios.post('https://sihaadmin.stechomeyazilim.info:8182/addTeacherEfso/send', newTeacher).then(responseTeacher=> {
          
         
            console.log("şmdsflş",getSchoolID)
          const newUserSchool = {
            Teacher_ID: responseTeacher.data.value[0].ID,
            School_ID:getSchoolID,
          };

          console.log("lösmdş1f",responseTeacher)
          axios.post('https://sihaadmin.stechomeyazilim.info:8182/addTeacherOfSchollEfso/send', newUserSchool).then(responseSchool => {

            console.log("lösmdş1f",responseSchool)
            showToast(true,'Başarıyla Koç Tanımlandı!')
          })
          
        })

      })

        // save new user
        setTimeout(() => {
        
            dispatch(onGetTeacherSchool(getSelectSchoolID))
         
        }, 1000)
  
        validation.resetForm();
      }
      toggle();
    },
  });

  const validationNew = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: (contactEdit && contactEdit.Teacher.Users.Users_NameSurname) || "",
      tel: (contactEdit && contactEdit.Teacher.Users.Users_TelephoneNumber) || "",
      password: (contactEdit && contactEdit.Teacher.Users.Users_LoginPasswordCode) || "",
      email: (contactEdit && contactEdit.Teacher.Users.Users_Email) || "",
    },
    validationSchema: Yup.object({
 
      username: Yup.string(3).required("Kullanıcı adı Giriniz"),

    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Users_NameSurname: values.username,
          Users_TelephoneNumber: values.tel,
          Users_LoginPasswordCode: values.password,
        //  UsersPhoto:getFileName,
          Users_Type_ID: getCategoryID,
          Users_Email:values.email,

        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        var localDate = moment().local();

        const newUser = {
          Users_NameSurname: values["username"],
          Users_LoginUsernameCode: values["username"],
          MailAdress:values["email"],
          Users_TelephoneNumber:values["tel"],
          Users_LoginPasswordCode: values["password"],
        //  UsersPhoto:getFileName,
          Users_Type_ID: 2,
          Users_CreatedDateTime:localDate
        };
        
        axios.post('https://sihaadmin.stechomeyazilim.info:8182/addUsersEfso/send', newUser).then(response => {
          
       
          const user = {
            Users_ID:response.data.value[0].ID,
           //Created_DateTime
            Teacher_ID:getTeacherID,
          }
    
          axios.post('https://sihaadmin.stechomeyazilim.info:8182/addTeacherUsersEfso/send', user).then(responseTeacher => {
    
            console.log("lmsdşlg",responseTeacher)
    
            showToast(true,'Başarıyla Öğrenci Tanımlandı!')
          setTimeout(async() => {
          
            dispatch(onGetTeacherSchool(getSelectSchoolID));
           
          }, 1000)
    
          
          })

      })

        // save new user
        console.log("kşmsdlşf",getSelectSchoolID)
        setTimeout(() => {
        
            dispatch(onGetTeacherSchool(getSelectSchoolID))
         
        }, 1000)
  
        validation.resetForm();
      }
      toggleAdd();
    },
  });
  function showToast(type) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Koç Tanımlandı"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const { schoolUser } = useSelector(state => ({
    schoolUser: state.contacts.schoolUser,
  }));

  const [modal, setModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [getCategoryID, setCategoryID] = useState(null);
  const [getSchoolID, setSchoolID] = useState(null);
  const [getLessonTypeID, setLessonTypeID] = useState(null);
  const [getSchoolData, setSchoolData] = useState([]);
  const [getSelectSchoolID, setSelectSchoolID] = useState(null);
  const [getSchoolTitle, setSchoolTitle] = useState(null);

  const [getUsersDefault, setUsersDefault] = useState([]);
  const [modalLocation, setModalLocation] = useState(false);
  const [getModalAdd, setModalAdd] = useState(false);

  const [getSelectedValuesUsers, setSelectedValuesUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [getUser, setUser] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [getTeacherID, setTeacherID] = useState([]);

  const _deleteUser = (ID) => {

    axios.delete(`https://sihaadmin.stechomeyazilim.info:8182/deleteTeacherOfUsersEfso/${ID}`).then(async(res) => {

      console.log("lmsdşcclg",res)
      setTimeout(async() => {
      
        dispatch(onGetTeacherSchool(getSelectSchoolID));
       
      }, 1000)
  
    })
  }
  
  useEffect(() => {
    if (getUser && !getUser.length) {

    _getUsersData()
    }

  }, [getUser]);

  const _getUsersData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersEfso/select/`)
      .then((res) => {
        setUser(res.data)
      })
  }


  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Kullanıcının girdiği metne göre seçenekleri filtrele
    const filtered = getUser.filter(option =>
        option.Users_NameSurname.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredOptions(filtered);
};
  
  const handleUserClickLocation = (arg) => {

    setUsersDefault(arg.SchoolOfUsers);

    setTeacherID(arg.Teacher_ID);

    toggleLocation()
  }
  

    
  const handleUserClickAdd = (arg) => {

    setUsersDefault(arg.SchoolOfUsers);

    setTeacherID(arg.Teacher_ID);

    toggleAdd()
  }
  
  const toggleAdd = () => {
    setModalAdd(!getModalAdd);
  };


  const toggleLocation = () => {
    setModalLocation(!modalLocation);
  };


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "ID",
        accessor: "Teacher_ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

   
      {
        Header: "Adı",
        accessor: "Teacher.Users.Users_NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

   

      {
        Header: "Telefon Numarası",
        accessor: "Teacher.Users.Users_TelephoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Kayıt Tarihi",
        accessor: "Teacher.Users.Users_CreatedDateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Mail Adresi",
        accessor: "Teacher.Users.MailAdress",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Öğrencileri",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.Teacher.Teacher_OfUsers == null ?  <span >
                 Öğrencisi Yok
                </span>: (
                   cellProps.Teacher.Teacher_OfUsers.map((item, index) => (
                    <div key={index}>
                      {item.Users != null ? 
                      <span >
                        <Button onClick={()=> _deleteUser(item.ID)}>
                          Sil
                        </Button>
                        {item.Users.ID} - {item.Users.Users_NameSurname}
                      </span> : null}
                    </div>))
                  )
            }
          </>
        ),
      },



      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">


<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickAdd(userData);
                }}
              >
                <i className="mdi mdi-checkbox-marked-outline font-size-18" id="addtooltip1" />
                <UncontrolledTooltip placement="top" target="addtooltip1">
                 Yeni Öğrenci Ekle
                </UncontrolledTooltip>
              </Link>

<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickLocation(userData);
                }}
              >
                <i className="mdi mdi-plus font-size-18" id="addtooltip" />
                <UncontrolledTooltip placement="top" target="addtooltip">
                  Öğrenci Ekle
                </UncontrolledTooltip>
              </Link>


              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>


              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleDeleteUser(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
             
            </div>
          );
        },
      },
    ],
    []
  );

  
    
  const _getSchoolDataNew = async (ID,title) => {

    setSelectSchoolID(ID)
    setSchoolTitle(title)

    dispatch(onGetTeacherSchool(ID))
   
  }



  const _getSchoolData = async () => {
    try {
      let authUser = JSON.parse(localStorage.getItem("authUser"));

      const res = await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getSchoolOfUsersEfso/select/${authUser.ID}`);

      setSchoolData(res.data);
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      await _getSchoolData();
    };
    fetchData(); // async fonksiyon çağırmak için
  }, []); // Bağımlılık dizisi boş bırakılarak sadece ilk render'da çalışması sağlanır
  

  const toggle = () => {
    setModal(!modal);
  };


  const handleUserClick = (arg) => {
    setEditContact(arg);


    console.log("smdfşcl",arg)

    setSchoolID(arg.School_ID)

    setIsEdit(true);


    toggle();
  };

  const handleSelectChangeCompany = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValuesUsers(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);



  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  
  const _setChoiceLessonType= arg => {
    setLessonTypeID(arg.target.value)
  }

  const _setChoiceSchool = arg => {
    setSchoolID(arg.target.value)
  }


  const _addUsers = () => {
    try {  
      console.log("şlsdmfşl",getSelectedValuesUsers,getSchoolID)
      getSelectedValuesUsers.map(item => {

      console.log("item121cc",getTeacherID)
      const location = {
        Users_ID:parseFloat(item),
       //Created_DateTime
        Teacher_ID:getTeacherID,
      }

      axios.post('https://sihaadmin.stechomeyazilim.info:8182/addTeacherUsersEfso/send', location).then(response => {

        console.log("lmsdşlg",response)

        showToast(true)
      setTimeout(async() => {
      
        dispatch(onGetTeacherSchool(getSelectSchoolID));
       
      }, 1000)

      
      })
}
    )

    toggleLocation()

  } catch (error) {
    console.log("_addLocation MapScreen")
    console.log(error)
  }
  }

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Koç Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  
              {  getSchoolData.map((item, index) => (
                                 <Col key={item.ID} sm="7">
                                 <div className="text-sm-end">
                                   <Button
                                     type="button"
                                     color= {getSelectSchoolID === item.ID ? "success" : "danger"}
                                     className={`btn-rounded mb-2 me-2 ${
                                      getSelectSchoolID === item.ID ? "selected-btn" : ""
                                    }`}
                                   
                                     onClick={()=> _getSchoolDataNew(item.School_ID,item.School.School_Title)}
                                   >
                                   
                                    {item.School.School_Title} Okulu({getSchoolData.length})
                                   </Button>
                                 </div>
                               </Col>
                                ))}
               
              { getSelectSchoolID == null ? 
               <Label className="form-label">Lütfen Önce Okul Seçiniz</Label>
              :   <TableContainer
              columns={columns}
              data={schoolUser}
              ref={tableRef}
              isGlobalFilter={true}
              isAddKoc={true}
              getSchoolTitle={getSchoolTitle}
              handleUserClick={handleUserClicks}
              customPageSize={10}
              className="custom-header-css"
            />}
                

                <Modal  size="lg" isOpen={getModalAdd} toggle={toggleAdd}>
                    <ModalHeader toggle={toggleAdd} tag="h4">
                      {!!isEdit ? "Düzenle" : "Yeni Öğrenci Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                          onSubmit={e => {
                            e.preventDefault();
                            validationNew.handleSubmit();
                            return false;
                          }}
                      >
                        
                        <Row>
                          <Col xs={12}>



                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Adı</Label>
                              <Input
                                name="username"
                                label="username"
                                type="text"
                                placeholder="Kullanıcı Adını Giriniz"
                                onChange={validationNew.handleChange}
                                onBlur={validationNew.handleBlur}
                                value={validationNew.values.username || ""}
                                invalid={
                                  validationNew.touched.username &&
                                  validationNew.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validationNew.touched.username &&
                                validationNew.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validationNew.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>



                            <div className="mb-3">
                              <Label className="form-label">Mail Adresi</Label>
                              <Input
                                name="email"
                                label="email"
                                type="text"
                                placeholder="Mail Adresi Giriniz"
                                onChange={validationNew.handleChange}
                                onBlur={validationNew.handleBlur}
                                value={validationNew.values.email || ""}
                                invalid={
                                  validationNew.touched.email &&
                                  validationNew.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validationNew.touched.email &&
                                validationNew.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validationNew.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                  

                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Şifre</Label>
                              <div style={{ display: "flex", alignItems: "center" }}>

                              <Input
                                name="password"
                                label="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Kullanıcı Şifresi"
                                onChange={validationNew.handleChange}
                                onBlur={validationNew.handleBlur}
                                value={validationNew.values.password || ""}
                                invalid={
                                  validationNew.touched.password &&
                                  validationNew.errors.password
                                    ? true
                                    : false
                                }
                              />

                                <Button
          type="button"
          onClick={togglePasswordVisibility}
          style={{ marginLeft: "10px" }}
        >
          {showPassword ? "Gizle" : "Göster"}
        </Button>
        </div>
                              {validationNew.touched.password &&
                                validationNew.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validationNew.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Öğrenim Durumunu Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getLessonTypeID}
                                onChange={(text) => _setChoiceLessonType(text)}
                                value={
                                  getLessonTypeID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>


                                {getLessonType.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Users_Lessons_Type_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>



                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Telefon</Label>
                              <Input
                                name="tel"
                                label="tel"
                                type="text"
                                placeholder="Kullanıcı Telefon"
                                onChange={validationNew.handleChange}
                                onBlur={validationNew.handleBlur}
                                value={validationNew.values.tel || ""}
                                invalid={
                                  validationNew.touched.tel &&
                                  validationNew.errors.tel
                                    ? true
                                    : false
                                }
                              />
                              {validationNew.touched.tel &&
                                validationNew.errors.tel ? (
                                <FormFeedback type="invalid">
                                  {validationNew.errors.tel}
                                </FormFeedback>
                              ) : null}
                            </div>
                           

                       
                          </Col>
                        </Row>
                         
                   
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                <Modal  size="lg" isOpen={modalLocation} toggle={toggleLocation}>
                    <ModalHeader toggle={toggleLocation} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kayıtlı Öğrenci Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();  // Sayfa yenilemesini engeller
                          _addUsers()
                        }}
                      >

	
     
      {/* Yeni enlem ve boylam alanı eklemek için bir düğme */}

      <div className="mb-3">
                              <Label className="form-label">Kullanıcı Seçiniz</Label>

                              <div className="mb-3">
                              <input
                type="text"
                className="form-control"
                placeholder="Arama yapın..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            </div>
            
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getSelectedValuesUsers}
                                multiple
                                onChange={(text) => handleSelectChangeCompany(text)}
                                value={
                                  getSelectedValuesUsers
                                }>
                                {filteredOptions.length > 0 ? filteredOptions.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          )) :  getUser.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          ))}

                              </Input>
                            
                            </div>

                         
                   
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>


                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kullanıcı Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>



                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Adı</Label>
                              <Input
                                name="username"
                                label="username"
                                type="text"
                                placeholder="Kullanıcı Adını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username &&
                                    validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                                validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>



                            <div className="mb-3">
                              <Label className="form-label">Email Adresi</Label>
                              <Input
                                name="email"
                                label="email"
                                type="text"
                                placeholder="Email Adresi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Okul Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getSchoolID}
                                onChange={(text) => _setChoiceSchool(text)}
                                value={
                                  getSchoolID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>


                                {getSchoolData.map((item, index) => (
                                  <option value={item.School_ID} key={item.ID}>{item.School.School_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Şifre</Label>
                              <div style={{ display: "flex", alignItems: "center" }}>

                              <Input
                                name="password"
                                label="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Kullanıcı Şifresi"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                               <Button
          type="button"
          onClick={togglePasswordVisibility}
          style={{ marginLeft: "10px" }}
        >
          {showPassword ? "Gizle" : "Göster"}
        </Button>
        </div>
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Telefon</Label>
                              <Input
                                name="tel"
                                label="tel"
                                type="text"
                                placeholder="Kullanıcı Telefon"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tel || ""}
                                invalid={
                                  validation.touched.tel &&
                                    validation.errors.tel
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.tel &&
                                validation.errors.tel ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tel}
                                </FormFeedback>
                              ) : null}
                            </div>
                           

                       
                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
