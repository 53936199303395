import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Status, TL } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";


import {
  getProduct as onGetProduct,
  addProduct as onAddProduct,
  updateProduct as onUpdateProduct,
  deleteProduct as onDeleteProduct,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Soru Alt Alt Kategori | EfsoKoç Admin PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getSubCategoryID, setSubCategoryID] = useState();


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Question_SubCategory_Sub_Text) || "",
      sort: (contactEdit && contactEdit.Sort) || null,
   
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),
    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          IsActive: getIsActive,
          Sort:values.sort,
          Question_SubCategory_ID:getSubCategoryID,
          Question_SubCategory_Sub_Text:values.title,
     
        };
        console.log("updateUser123",updateUser)
        dispatch(onUpdateProduct(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          IsActive: getIsac,
          Sort:values["sort"],
          Question_SubCategory_ID:getSubCategoryID,
          Question_SubCategory_Sub_Text:values["title"],
        };
        // save new user
        dispatch(onAddProduct(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.products,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [getSubCategory, setSubCategory] = useState([]);
  const [getIsActive, setIsActive] = useState(false);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Adı",
        accessor: "Question_SubCategory_Sub_Text",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Alt Kategori Adı",
        accessor: "Question_SubCategory.Question_SubCategory_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Sıralama",
        accessor: "Sort",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "Aktif Mi?",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },



      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

        

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getQuestionSubCategoryEfso/select/`)
      .then((res) => {
        setSubCategory(res.data)
      })

  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetProduct());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };


  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setSubCategoryID(user.Question_SubCategory_ID)
    setIsActive(user.IsActive)
    setIsEdit(true);


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteProduct(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  

  const _setChoiceSubCategory = arg => {
    setSubCategoryID(arg.target.value)
  }



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Soru Alt Alt Kategorisi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddProduct={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

            

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Ürün Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>



                            <div className="mb-3">
                              <Label className="form-label">Adı</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Adı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Sıralama</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="number"
                                placeholder="Sıralama"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>

                     
                            <div className="mb-3">
                              <Label className="form-label">Alt kategori Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getSubCategoryID}
                                onChange={(text) => _setChoiceSubCategory(text)}
                                value={
                                  getSubCategoryID
                                }>
                                  <option value="">Lütfen Seçim Yapınız</option>

                                {getSubCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Question_SubCategory_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                   
                            <div className="d-flex">
                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                         
                        </div>

                      
                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
