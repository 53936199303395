import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import "jspdf-autotable";
import * as XLSX from 'xlsx';

import {
  getBook as onGetBook,
  addBook as onAddBook,
  updateBook as onUpdateBook,
  deleteBook as onDeleteBook,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Status } from "../Users/contactlistCol";



const ContactsList = props => {

  //meta title
  document.title = "Kitap Listesi | EfsoKoç ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEditContactTeacher, setEditContactTeacher] = useState();
  const [getIsActive, setIsActive] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);



  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (getEditContactTeacher && getEditContactTeacher.Book_Title) || "",
      pageRange: (getEditContactTeacher && getEditContactTeacher.Book_PageRange) || '',
    },
    validationSchema: Yup.object({
    
   
    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contact.ID,
          Book_Title: values.name != null ? values.name : null,
          //Book_PageRange: values.pageRange,
          Lesson_ID : getCategoryID,
          IsActive:getIsActive
        };
        console.log("updateUser12123",updateUser)
        // update user
        dispatch(onUpdateBook(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Book_Title: values["name"],
         // Book_PageRange: values["pageRange"],
          Lesson_ID : getCategoryID,
          IsActive:getIsActive

        };
        
        console.log("newUser12331",newUser)
        dispatch(onAddBook(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });



  const { users } = useSelector(state => ({
    users: state.contacts.book,
  }));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);


  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }


  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonEfso2/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
  
  }, [dispatch, getCategory]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Başlık",
        accessor: "Book_Title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
    

      {
        Header: "Ders",
        accessor: "Lesson.Lesson_Title",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },

      {
        Header: "Aktiflik Durumu",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
    
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              

             
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetBook());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditContactTeacher(user)
    setContact(user);
    setIsEdit(true);

    setIsActive(user.IsActive)
    setCategoryID(user.Lesson_ID)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteBook(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const arrayBufferToBinaryString = (buffer) => {
    const binaryArray = new Uint8Array(buffer);
    const binaryString = binaryArray.reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, '');
    return binaryString;
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (event) => {
      const binaryString = arrayBufferToBinaryString(event.target.result);
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      // Assuming the first row contains headers
      const headers = data[0];
  
      const formattedData = data.slice(1).map((row) => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[headers[index]] = cell;
        });
        return rowData;
      });
  
      const startIndex1 = 26;
      const endIndex1 = 41;
      const startIndex2 = 50;
      const endIndex2 = 60;
      
      formattedData.forEach(async (row) => {
        const valuesArray = Object.values(row);
        
        const bookSubSubjectTitle1 = valuesArray.length > startIndex1 ?
          valuesArray.slice(startIndex1, endIndex1).filter(cell => cell !== undefined) : [];
      
        const bookSubSubjectTitle2 = valuesArray.length > startIndex2 ?
          valuesArray.slice(startIndex2, endIndex2).filter(cell => cell !== undefined) : [];
      
        const bookSubSubjectTitle = [...bookSubSubjectTitle1, ...bookSubSubjectTitle2];
        console.log("Final Book_SubSubject_Title:", bookSubSubjectTitle);
      
        const newOrder = {
          Book_SubSubject_Title: bookSubSubjectTitle,
          Page: row.SYF, // Ensure this key exists
        };
      
        await axios.post(`https://buharaapi.stechomeyazilim.info:5210/postBookSubSubject/insert`, newOrder)
          .then(async (res) => {
            console.log("Response:", res);
          });
      
        setTimeout(() => dispatch(onGetRecipes()), 1000);
      });
      
  
     
    };
  
    reader.readAsArrayBuffer(file);
  };
  
  


  const handleClick = () => {
    document.getElementById('fileInput').click();
  };


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Kullanıcı" breadcrumbItem="Kitap Listesi" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                <button 
                color="success"
      className="btn-rounded  mb-2 me-2" 
      onClick={handleClick}>Excel Dosyasını Yükle</button>
      <input
        id="fileInput"
        type="file"
        accept=".xlsx, .xls"
        style={{ display: 'none' }}
        
        onChange={handleFileUpload}
      />

                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddBook={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Kitap Düzenle" : "Kitap Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >      

                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Kitap Adı</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Kitap Adı"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          
                    
                       {/**     <div className="mb-3">
                              <Label className="form-label">Sayfa Aralığı</Label>
                              <Input
                                name="pageRange"
                                type="text"
                                placeholder="Sayfa Aralığı"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pageRange || ""}
                                invalid={
                                  validation.touched.pageRange &&
                                    validation.errors.pageRange
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.pageRange &&
                                validation.errors.pageRange ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pageRange}
                                </FormFeedback>
                              ) : null}
                            </div>*/} 
                      

                            <div className="mb-3">
                              <Label className="form-label">Ders Seçiniz</Label>
                              <Input
                                name="getCategoryID"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                 <option value="">Lütfen Seçim Yapınız</option>

                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Lesson_Title} - {item.Users_Lessons_Type.Users_Lessons_Type_Title}</option>
                                ))}

                              </Input>
                            
                            </div>


          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
